import style from "./style.module.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "@src/stores";
import { useParams } from "react-router";
import { get } from "lodash";

const typeIcon = {
  'game.start-ar-image-tracking': '/icon/ImageTracking.svg',
  'game.start-ar-marker': '/icon/ARMarker.svg',
  'game.start-ar-video': '/icon/ARMarker.svg',
  'game.start-ar-portal': '/icon/ARMarker.svg',
  'game.start-ar-geolocation': '/icon/Geolocation.svg'
}
const AR = ({ type, stepContent, stepController }: any) => {
  const { fireStore } = useStore();
  console.log(type);
  const { id: groupId } = useParams();
  return (
    <div className={style['btn-wrapper']}>
      <div className={'d-flex justify-content-center'}>
        <div className={style.circleButtons} onClick={() => {
          const option = stepController[0]
          fireStore.pushGameMode('ar')
          fireStore.setLocalResources({ type, stepContent, groupId, optionId: option.id })
        }}>
          <img src={get(typeIcon, type,'/icon/ImageTracking.svg')} alt={'map'} />
        </div>
      </div>
    </div>
  )
}
export default observer(AR);
