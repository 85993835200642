import { i18n } from '@lingui/core'
import config from '@src/lib/firebase-config'
import { makeAutoObservable } from 'mobx'

import { doc, DocumentData, DocumentSnapshot, getDoc, getFirestore, onSnapshot, Unsubscribe } from 'firebase/firestore'

type GameStatus = 'getPermission' | 'map' | 'local' | 'remote' | 'ar'
interface FireStore {
  getDocument: (
    collectionName: string,
    documentId: string,
    callback: (data: DocumentSnapshot<DocumentData>) => void
  ) => Promise<void>
  getDocumentByRef: (ref: any) => Promise<void>
  subscribeSnapshot: (
    path: string,
    id: string,
    callback: (snapshot: DocumentSnapshot<DocumentData>) => void,
    errorCallback: any
  ) => Unsubscribe
  pushGameMode: (status: GameStatus) => void
  popGameMode: () => void
  setGameResources: (docSnap: any) => void
  setLocalResources: (data: any) => void
  setCurrentScence: (docSnap: any) => void
  setGameMessage: (docSnap: any) => void
  readonly currentGameMode: GameStatus
  gameResources: any
  currentScence: any
  gameMessage: any
  gameStatus: Array<GameStatus>
  localResources: any
  loading: boolean
}
const store = () => {
  const db = getFirestore(config)
  const data: FireStore = makeAutoObservable({
    gameResources: null,
    currentScence: null,
    gameMessage: { messages: [] },
    localResources: null,
    gameStatus: ['getPermission'],
    loading: false,
    pushGameMode: (status: GameStatus) => {
      console.log(data)
      data.gameStatus.push(status)
      // history.pushState(null, '', `?t=${Date.now()}`)
    },
    popGameMode: () => {
      console.log(data)
      data.gameStatus.pop()
      // history.pushState(null, '', `?t=${Date.now()}`)
    },
    setGameResources: (docSnap) => {
      const locale = () => {
        switch (data.gameResources.locale) {
          case 'zh-TW':
          case 'zh-CN':
          case 'zh-HK':
            return 'zh'
          case 'en':
            return 'en'
          case 'jp':
            return 'jp'
          default:
            return 'en'
        }
      }
      data.gameResources = docSnap.data()
      i18n.activate(locale())
    },
    setLocalResources: (resources) => {
      console.log('setLocalResources', resources)
      data.localResources = resources
    },
    setCurrentScence: (docSnap) => {
      data.currentScence = docSnap.data()
    },
    setGameMessage: (docSnap) => {
      const newMessages = docSnap.data()
      const diff = data.gameMessage.messages.length - newMessages.messages.length
      if (diff > 0) data.gameMessage.messages = [] //reset
      else data.gameMessage.messages.push(...newMessages.messages.slice(diff)) // new messages
    },
    get currentGameMode() {
      return data.gameStatus[data.gameStatus.length - 1]
    },
    async getDocument(collectionName, documentId, callback) {
      const docRef = doc(db, collectionName, documentId)
      // const docRef = collection(db, "cities");
      const docSnap = await getDoc(docRef)
      // Create a query against the collection.
      callback(docSnap)
    },
    subscribeSnapshot(path, id, callback, errorCallback) {
      console.log(`start sub ${path}/${id}`)
      return onSnapshot(doc(db, path, id), callback, errorCallback)
    },
  } as FireStore)
  return data
}

export default store
