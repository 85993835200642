import {useCallback, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import Webcam from "react-webcam";
import Options from "@src/views/game/components/Step/components/Stepper";
import get from "lodash/get";
import find from "lodash/find";
import {updateStepper} from "@src/services/games";
import Image from 'react-bootstrap/Image'
import localForage from "localforage";
import styles from "@src/views/game/components/Step/styles.module.scss";
import {toJS} from "mobx";
import {useParams} from "react-router-dom";
import {canvasToBlob} from "blob-util";
import {has} from "lodash";
import Loader from "@src/components/Loader";

const TakingPhoto = ({stepContent, stepController}: any) => {
    const cameraRef = useRef(null);
    const {id: groupId} = useParams();
    const capture = useCallback(
        async () => {
            try {
                if (cameraRef.current) {
                    if (flashLayer) {
                        flashLayer.classList.add('flash-effect');
                    }
                    const photo = (cameraRef as any).current.getScreenshot();

                    await localForage.setItem(photoName, photo);
                    setPhotoTaken(true);
                } else {
                    await updateStepper(groupId as string, get(stepController, '[0].id'), {photo: null})
                }
            } catch (e) {
                console.log(e)
            }
        },
        [cameraRef]
    );
    const [loading, setLoading] = useState(true);

    const handleUserMedia = () => setTimeout(() => setLoading(false), 1_000);
    // const [numberOfCameras, setNumberOfCamera] = useState(0);
    const foregroundUrl = find(stepContent, 'foreground.data.attributes.url') ? get(stepContent, 'foreground.data.attributes.url') : get(stepContent, 'foreground.url');
    const flashLayer = document.getElementById('flash-lyr') as HTMLElement;
    console.log(toJS(stepController))
    const [photoName] = useState(`photo-${stepController[0].id}`);
    const [photoTaken, setPhotoTaken] = useState(false);
    console.log('photoName', photoName);


    if (!photoTaken) {
        return (
            <>
                <div id="flash-lyr" className={"flash"}/>
                {foregroundUrl && <Image key={'foreground'}
                                         style={{
                                             zIndex: 1,
                                             position: 'absolute',
                                             bottom: 0,
                                             left: 0,
                                             // height: '100vh',
                                             width: '100%',
                                             // minWidth: '600px',
                                             // mixBlendMode: 'lighten',
                                         }}
                                         src={foregroundUrl}
                />}
                <Webcam
                    audio={false}
                    height={window.innerHeight}
                    ref={cameraRef}
                    mirrored={true}
                    screenshotFormat="image/jpeg"
                    width={window.innerWidth}
                    onUserMedia={handleUserMedia}
                    videoConstraints={{
                        width: window.innerHeight,
                        height: window.innerWidth,
                        facingMode: "user"
                    }}
                />
                {loading ? <Loader/> :
                    <div className={'fixed-bottom position-absolute'}>
                    <Options stepController={[{
                        ...get(stepController, '0'),
                        color: 'light',
                        theme: 'camera-btn',
                        icon: {
                            data: {
                                attributes: {
                                    url: '/icon/camera.svg'
                                }
                            }
                        }
                    }]}
                             onClick={capture}
                    />
                    </div>
                    }
            </>
        )
    } else {
        return (
            <>
                <div className={styles.retakeBtn}
                     onClick={() => setPhotoTaken(false)}
                ><img src={'/icon/chevron-left.svg'}/></div>
                <div className={styles.ScrollSection}>
                    <div className={styles.center}>
                        <div className={styles.postcard}>
                            <iframe id={"gererativeArt"}
                                    src={`/generative/group-pic/?s=${photoName}${foregroundUrl ? `&f=${foregroundUrl}` : ''}&w=${window?.innerWidth}&h=${window?.innerHeight}`}
                                    width={`100%`} height={`100%`} title="Gen Art"/>
                        </div>
                    </div>
                    <div className={'my-3 text-center d-flex fixed-bottom'}>
                        <Options stepController={[{
                            ...get(stepController, '0'),
                            color: 'light',
                            theme: 'camera-btn',
                            // title: '',
                            icon: {
                                data: {
                                    attributes: {
                                        url: '/icon/download.svg'
                                    }
                                }
                            }
                        }]}
                                 onClick={async ({groupId, optionId, extra}: any) => {
                                     try {
                                         console.log('photo:', groupId, optionId, extra)
                                         // const photo: any = await localForage.getItem(photoName);
                                         const photo: any = (document as any).getElementById('gererativeArt')?.contentWindow?.savep5inparent();
                                         console.log('photo canvas', photo)
                                         const blobCallback = async (blob: any) => {
                                             console.log('in blob callback', blob)
                                             const data = {
                                                 files: [
                                                     new File([blob], 'geng-art.jpeg', {
                                                         type: blob.type,
                                                     }),
                                                 ],
                                                 title: 'Show you some new.',
                                                 text: 'I made this with the new app, check it out!',
                                             };
                                             if (navigator.share) {
                                                 navigator.share(data).then(async () => {
                                                     console.log('Thanks for sharing!');
                                                     await updateStepper(groupId as string, optionId, {
                                                         ...extra,
                                                         photo: null
                                                     })
                                                 })
                                                     .catch(async (err) => {
                                                         console.log(err);
                                                         if (err.toString().includes('AbortError')) {
                                                             console.log(err)
                                                         } else {
                                                             alert(err);
                                                         }
                                                         await updateStepper(groupId as string, optionId, {
                                                             ...extra,
                                                             photo: null
                                                         })
                                                     });
                                             } else {
                                                 await updateStepper(groupId as string, optionId, {
                                                     ...extra,
                                                     photo: null
                                                 })
                                             }
                                         }
                                         if (has(photo, 'toBlob')) {
                                             await photo.toBlob(blobCallback)
                                         } else {
                                             const blob = await canvasToBlob(photo, 'image/jpeg');
                                             blobCallback(blob)
                                         }
                                     } catch (e) {
                                         await updateStepper(groupId as string, optionId, {...extra, photo: null})
                                         // alert(e)
                                     }
                                 }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default observer(TakingPhoto);
