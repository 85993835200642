import { i18n } from '@lingui/core'
import { reset } from '@src/services/games'
import { useNavigate, useParams } from 'react-router-dom'

const Reset = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  reset(id as string).then(() => {
    alert(i18n._('Reset succeed'))
    navigate(`/game/${id}?new=x`)
  })

  return <></>
}

export default Reset
