import {useStore} from '@src/stores';
import {observer} from 'mobx-react-lite';
import Iframe from 'react-iframe'
import get from 'lodash/get'
import {useEffect} from 'react';
import {updateStepper} from '@src/services/games';
import GameLayout from '@src/components/Layout/GameLayout';
import find from "lodash/find";

const arUrlAdapter = (type: any, stepContent: any) => {
    const {scale, rotateDeg, model, pattern, latitude, longitude, location, } = stepContent
    const params = new URLSearchParams();
    console.log(JSON.parse(JSON.stringify(stepContent)));
    console.log(type);
    switch (type) {
        case 'game.start-ar-geolocation': {
            const modelUrl = find(model, 'data.attributes.url') ?
                get(model, 'data.attributes.url', '') :
                get(model, 'url', '')
            params.set('m', modelUrl)
            params.set('y', rotateDeg)
            params.set('lat', latitude || location[1])
            params.set('lng', longitude || location[0])
            // params.set('lang', locale)
            console.debug(`/scan/location.html?${params.toString()}`)
            return `/scan/location.html?${params.toString()}`
        }
        case 'game.start-ar-image-tracking': {
            const modelUrl = find(model, 'data.attributes.url') ?
                get(model, 'data.attributes.url', '') :
                get(model, 'url', '')
            const patternUrl = find(pattern, 'data.0.attributes.url') ?
                get(pattern, 'data.0.attributes.url', '') :
                get(pattern, '0.file.url', '').replace(/\.[^/.]+$/, "")
            const duration = get(stepContent, 'duration', 100)
            const hintImage =
                find(stepContent, 'hintImage.data.attributes.ur') ?
                    get(stepContent, 'hintImage.data.attributes.url', '') :
                    get(stepContent, 'hintImage.url', '')
            const scanningInstruction = get(stepContent, 'scanningInstruction', '')
            params.set('m', modelUrl)
            params.set('p', patternUrl)
            params.set('s', scale)
            params.set('d', duration)
            params.set('hi', hintImage)
            if (scanningInstruction) params.set('si', scanningInstruction)
            console.debug(`/scan/image.html?${params.toString()}`)
            return `/scan/image.html?${params.toString()}`
        }
        case 'game.start-ar-video': {
            const {scale, video, marker, opacity} = stepContent
            const videoUrl = find(video, 'data.attributes.url') ?
            get(video, 'data.attributes.url', '') :
            get(video, 'url', '')
            
            params.set('video', videoUrl)
            params.set('scale', scale)
            params.set('opacity', opacity)
            params.set('marker', marker)
            console.debug(`/scan/dynamic-video.html?${params.toString()}`)
            return `/scan/dynamic-video.html?${params.toString()}`
        }
        case 'game.start-ar-portal': {
            const {video, marker} = stepContent
            const videoUrl = find(video, 'data.attributes.url') ?
            get(video, 'data.attributes.url', '') :
            get(video, 'url', '')
            
            params.set('video', videoUrl)
            params.set('marker', marker)
            console.debug(`/scan/dynamic-portal.html?${params.toString()}`)
            return `/scan/dynamic-portal.html?${params.toString()}`
        }
        case 'game.start-ar-marker':
        default: {
            const modelUrl =
                find(model, 'data.attributes.url') ?
                    get(model, 'data.attributes.url', '').replace('https://storage.googleapis.com/artifacts-cms/', '') :
                    get(model, 'url', '')
            const patternUrl =
                find(pattern, 'data.0.attributes.url') ?
                    get(pattern, 'data.0.attributes.url', '').replace('https://storage.googleapis.com/artifacts-cms/', '') :
                    get(pattern, '0.file.url', '').replace(/\.[^/.]+$/, "")
            params.set('m', modelUrl)
            params.set('p', patternUrl)
            // params.set('t', title)
            params.set('s', scale)
            params.set('h', rotateDeg)
            console.debug(`/scan/marker.html?${params.toString()}`)
            return `/scan/marker.html?${params.toString()}`
        }
    }
}
const AR = observer(({resource}: any) => {
    const {fireStore} = useStore()
    console.log('resource', resource)
    const {type, stepContent, groupId, optionId} = resource
    const arScannerUrl = `${arUrlAdapter(type, stepContent)}`;
    console.log('arScannerUrl', arScannerUrl)
    useEffect(() => {
        (window as any).sendARMessage = ((groupId: string, optionId: string) => {
            return (success: boolean) => {
                if (success) {
                    console.log('success', success);
                    // clearTimeout(time)
                    updateStepper(groupId, optionId, {skipScene: true})
                    fireStore.popGameMode()
                }
            }
        })(groupId as string, optionId);
    }, [])
    return (
        <GameLayout theme={'minimal'}>
            <Iframe
                width={`${window.screen.width}px`}
                height={`${window.screen.height}px`}
                allow={'xr-spatial-tracking;autoplay; camera;'}
                url={arScannerUrl}/>
        </GameLayout>
    )
})

export default AR;
