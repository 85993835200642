import { Avatar, Message } from '@chatscope/chat-ui-kit-react'
import { useStore } from '@src/stores'
import { SlateToReact } from '@src/util/SlateToReact'
import style from '@src/views/game/components/Scene/Message/style.module.scss'
import { get, has } from 'lodash'
import find from 'lodash/find'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import useSound from 'use-sound'
import PopupArtifact from './PopupArtifact'

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

const MessageHandler = observer(() => {
  const { fireStore, userStore } = useStore()
  const { messages } = fireStore.gameMessage
  const { stepFlow } = fireStore.currentScence
  const isTypeing = stepFlow[0].__component === 'game.step-message'

  const [playChat] = useSound('/sound/chat.mp3')
  const [playReply] = useSound('/sound/reply.mp3')
  const playSound = useCallback(
    (direction: 'incoming' | 'outgoing') => {
      console.debug('playSound', direction)
      if (direction === 'incoming') {
        console.debug('playSound chat')
        playChat()
      } else {
        console.debug('playSound reply')
        playReply()
      }
    },
    [playChat, playReply]
  )
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause()
        audio.removeAttribute('src') // empty source
        audio.load()
      }
    }
  }, [audio])

  const playVoiceOver = useCallback(
    (soundUrl: string) => {
      if (audio) {
        audio.pause()
        audio.src = soundUrl
        audio.load()
        audio.play()
      } else {
        const newAudio = new Audio(soundUrl)
        newAudio.play()
        setAudio(newAudio)
      }
    },
    [audio]
  )
  const modalController: any = useLocalObservable(() => ({
    show: false,
    popupDetail: {},
    handleClose: () => {
      modalController.show = false
    },
    handleShow: (popupDetail: any) => {
      modalController.popupDetail = popupDetail
      modalController.show = true
    },
  }))
  return (
    <>
      {React.Children.toArray(
        messages.map((content: any, index: number) => {
          const prevSender = get(messages, `${index - 1}.sender`)
          const isSameSender = prevSender === get(content, 'sender')
          const withHeader = !isSameSender
          const isSelf = get(content, 'direction', 'incoming') === 'outgoing'
          const isLastMessage = messages.length - 1 === index
          console.log('messages', JSON.parse(JSON.stringify(messages)), get(content, 'type'))

          switch (get(content, 'type')) {
            case 'artifact': {
              return (
                <MessageArtifact
                  content={content}
                  handlePopup={modalController.handleShow}
                  playSound={playSound}
                  withSound={isLastMessage}
                  withHeader={withHeader}
                  isSelf={isSelf}
                />
              )
            }
            case 'image': {
              return (
                <MessageImage
                  content={content}
                  playSound={playSound}
                  handlePopup={modalController.handleShow}
                  withSound={isLastMessage}
                  withHeader={withHeader}
                  isSelf={isSelf}
                />
              )
            }
            case 'system': {
              return <MessageSystem content={content} />
            }
            case 'richText': {
              return (
                <MessageRichtext
                  content={content}
                  playSound={playSound}
                  withSound={isLastMessage}
                  withHeader={withHeader}
                  isSelf={isSelf}
                />
              )
            }
            case 'video': {
              console.log('video here')
              console.log(content)
              return (
                <MessageVideo
                  content={content}
                  handlePopup={modalController.handleShow}
                  playSound={playSound}
                  withSound={true}
                  withHeader={false}
                  isSelf={false}
                />
              )
            }
            default: {
              return (
                <MessageText
                  content={content}
                  playSound={playSound}
                  withSound={isLastMessage}
                  withHeader={withHeader}
                  isSelf={isSelf}
                  playVoiceOver={playVoiceOver}
                />
              )
            }
          }
        })
      )}
      {isTypeing && (
        <Message
          type="text"
          model={{
            position: 0,
            direction: 'incoming',
          }}
          avatarSpacer={true}
        >
          <Message.ImageContent src={'/gif/Chat loader.gif'} alt="loading avatar" width={30} />
        </Message>
      )}
      <PopupArtifact
        open={modalController.show}
        detail={modalController.popupDetail}
        handleClose={modalController.handleClose}
      />
    </>
  )
})

export default MessageHandler

const MessageSystem = React.memo(function ({ content }: any) {
  console.debug('MessageSystem')
  return (
    <Message.CustomContent>
      <div className={'d-block text-center'}>
        <span className={style.initChatBox}>
          {content.avatar?.data?.attributes?.url && (
            <img
              src={content.avatar?.data?.attributes?.url}
              className={'d-inline-block me-1 mt-1 ' + style.sceneAvatar}
            />
          )}
          {content.message}
        </span>
      </div>
    </Message.CustomContent>
  )
})
const MessageArtifact = React.memo(({ content, handlePopup, withHeader, isSelf, playSound, withSound }: any) => {
  console.debug('MessageArtifact')
  const { image, sender, id, i, popup, imageSize, avatar, direction } = content
  const img = find(image, 'data.0.attributes.url')
    ? get(image, 'data.0.attributes.url')
    : get(image, '0.sliderImage.url')
  const hasAvatar = withHeader && (has(avatar, 'data.attributes.url') || has(avatar, 'url'))
  useEffect(() => {
    withSound && playSound(direction)
  }, [content])
  return (
    <Message
      type="text"
      model={{
        position: 0,
        direction,
        sender,
      }}
      onClick={() => {
        handlePopup({
          videoId: '',
          type: 'picture',
          description: '',
          name: '',
          gallery: { data: [{ attributes: { url: img } }] },
        })
        console.debug('disabled popup')
      }}
      avatarPosition="tl"
      avatarSpacer={!hasAvatar && !isSelf}
    >
      {withHeader && <Message.Header sender={sender} sentTime="" />}
      {hasAvatar && (
        <Avatar
          src={has(avatar, 'data.attributes.url') ? get(avatar, 'data.attributes.url') : get(avatar, 'url')}
          name={sender}
        />
      )}
      <Message.CustomContent>
        <Message.ImageContent src={img} alt="avatar" style={{ width: `${imageSize}%`, maxHeight: '300px' }} />
        <Message.TextContent text={`Congratulations! You've earned this new reward: ${popup.name}`} />
        <Message.TextContent text={popup.description} />
      </Message.CustomContent>
    </Message>
  )
})
const MessageRichtext = React.memo(({ content, playSound, withHeader, isSelf, withSound }: any) => {
  console.log('MessageRichtext')
  const { message, direction, sender, avatar, richText } = content
  const hasAvatar = withHeader && (has(avatar, 'data.attributes.url') || has(avatar, 'url'))
  useEffect(() => {
    withSound && playSound(direction)
  }, [content])
  console.log('richText ', JSON.stringify(richText))
  return (
    <Message
      type="text"
      model={{
        position: 0,
        direction,
        sender,
      }}
      avatarSpacer={!hasAvatar && !isSelf}
      avatarPosition="tl"
    >
      {withHeader && <Message.Header sender={sender} sentTime="" />}
      {withHeader && has(avatar, 'data.attributes.url') && (
        <Avatar src={get(avatar, 'data.attributes.url')} name={sender} />
      )}
      <Message.CustomContent>
        <div dangerouslySetInnerHTML={{ __html: SlateToReact(richText) }} />
        <Markdown components={{ a: LinkRenderer }}>{richText}</Markdown>
      </Message.CustomContent>
    </Message>
  )
})

const MessageText = React.memo(({ content, playSound, withHeader, isSelf, withSound, playVoiceOver }: any) => {
  console.debug('MessageText')
  const { message, sender, direction, avatar, voiceOver } = content
  const { userStore } = useStore()
  useEffect(() => {
    withSound &&
      (voiceOver && (userStore.user.isEarphoneMode || userStore.user?.isEarphoneMode === undefined)
        ? playVoiceOver(voiceOver)
        : playSound(direction))
  }, [content])
  const hasAvatar = withHeader && (has(avatar, 'data.attributes.url') || has(avatar, 'url'))
  return (
    <Message
      type="text"
      model={{
        position: 0,
        direction,
        sender,
      }}
      avatarSpacer={!hasAvatar && !isSelf}
      avatarPosition="tl"
    >
      {withHeader && <Message.Header sender={sender} sentTime="" />}
      {hasAvatar && (
        <Avatar
          src={has(avatar, 'data.attributes.url') ? get(avatar, 'data.attributes.url') : get(avatar, 'url')}
          name={sender}
        />
      )}
      <Message.TextContent text={message} />
    </Message>
  )
})

const MessageImage = React.memo(({ content, playSound, handlePopup, withHeader, isSelf, withSound }: any) => {
  console.debug('MessageImage')
  const { image, direction, sender, id, imageSize, avatar } = content
  const img = has(image, 'data.attributes.url') ? get(image, 'data.attributes.url') : get(image, 'url')
  const hasAvatar = withHeader && (has(avatar, 'data.attributes.url') || has(avatar, 'url'))
  useEffect(() => {
    withSound && playSound(direction)
  }, [content])
  return (
    <Message
      model={{
        position: 0,
        direction,
        sender,
      }}
      onClick={() => {
        handlePopup({
          videoId: '',
          type: 'picture',
          description: '',
          name: '',
          gallery: { data: [{ attributes: { url: img } }] },
          download: true,
        })
        console.debug('disabled popup')
      }}
      avatarSpacer={!hasAvatar && !isSelf}
      avatarPosition="tl"
    >
      {withHeader && <Message.Header sender={sender} sentTime="" />}
      {hasAvatar && (
        <Avatar
          src={has(avatar, 'data.attributes.url') ? get(avatar, 'data.attributes.url') : get(avatar, 'url')}
          name={sender}
        />
      )}
      <Message.ImageContent
        src={img}
        alt="avatar"
        style={{ width: `${imageSize}%`, maxHeight: '300px', backgroundColor: 'white' }}
      />
    </Message>
  )
})

const MessageVideo = React.memo(({ content, playSound, handlePopup, withHeader, isSelf, withSound }: any) => {
  console.debug('MessageVideo')
  const { image, direction, sender, videoId, imageSize, avatar } = content
  const img = has(image, 'data.attributes.url') ? get(image, 'data.attributes.url') : get(image, 'url')
  const hasAvatar = withHeader && (has(avatar, 'data.attributes.url') || has(avatar, 'url'))
  useEffect(() => {
    withSound && playSound(direction)
  }, [content])
  return (
    <Message
      model={{
        position: 0,
        direction,
        sender,
      }}
      onClick={() => {
        handlePopup({
          videoId: videoId,
          type: 'video',
          description: '',
          name: '',
          gallery: { data: [{ attributes: { url: img } }] },
          download: true,
        })
        console.debug('disabled popup')
      }}
      avatarSpacer={!hasAvatar && !isSelf}
      avatarPosition="tl"
    >
      {withHeader && <Message.Header sender={sender} sentTime="" />}
      {hasAvatar && (
        <Avatar
          src={has(avatar, 'data.attributes.url') ? get(avatar, 'data.attributes.url') : get(avatar, 'url')}
          name={sender}
        />
      )}
      <Message.ImageContent src={'/icon/video.svg'} alt={'play'} width={70} />
    </Message>
  )
})
