import GameLayout from '@src/components/Layout/GameLayout'
import { useStore } from '@src/stores'
import Step from '@src/views/game/components/Step'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import MessageHandler from './components/MessageHandler'
import './styles/main.scss'

function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight)
}

export default observer(() => {
  const { fireStore } = useStore()
  const { messages } = fireStore.gameMessage
  const { stepFlow } = fireStore.currentScence
  const messageArr = JSON.parse(JSON.stringify(messages))

  useEffect(() => {
    setTimeout(scrollToBottom, 100)
  }, [window, messageArr])

  return (
    <GameLayout>
      <div style={{ height: '30px' }} />
      <Container className={'p-3'}>
        <MessageHandler />
      </Container>
      <div style={{ height: '33vh' }} />
      <Step step={stepFlow[0]} />
    </GameLayout>
  )
})
