import { i18n } from '@lingui/core'
import { Button } from '@src/components/Buttons/Button'
import Loader from '@src/components/Loader'
import { updateStepper } from '@src/services/games'
import { useStore } from '@src/stores'
import get from 'lodash/get'
import has from 'lodash/has'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const popupBlockerChecker = {
  check: function (popup_window: any) {
    if (popup_window) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        setTimeout(function () {
          popupBlockerChecker.is_popup_blocked(popup_window)
        }, 200)
      } else {
        popup_window.onload = function () {
          popupBlockerChecker.is_popup_blocked(popup_window)
        }
      }
    } else {
      popupBlockerChecker.displayError()
    }
  },
  is_popup_blocked: function (popup_window: any) {
    if (popup_window.innerHeight > 0 == false) {
      popupBlockerChecker.displayError()
    }
  },
  displayError: function () {
    alert('Popup Blocker is enabled! If you are using iPhone or iPad, go to Settings > Safari. Turn off Block Pop-ups.')
  },
}
const arUrlAdapter = (type: any, stepContent: any) => {
  const { scale, rotateDeg, model, pattern, latitude, longitude, title } = stepContent
  const params = new URLSearchParams()
  switch (get(type, '0.__component')) {
    case 'scene.geo-ar': {
      const modelUrl = get(model, 'data.attributes.url', '')
        .replace('https://storage.googleapis.com/artifacts-cms/', '')
        .split('.')
        .shift()
      params.set('m', modelUrl)
      params.set('y', rotateDeg)
      params.set('lat', latitude)
      params.set('lng', longitude)
      return `/scan/location.html?${params.toString()}`
    }
    case 'scene.image-tracking': {
      const modelUrl = get(model, 'data.attributes.url', '').replace(
        'https://storage.googleapis.com/artifacts-cms/',
        ''
      )
      const patternUrl = get(pattern, 'data.0.attributes.name', '').split('.').shift()
      params.set('m', modelUrl)
      params.set('p', patternUrl)
      params.set('s', scale)
      params.set('d', '100')
      return `/scan/image.html?${params.toString()}`
    }
    case 'scene.marker-ar':
    default: {
      const modelUrl = get(model, 'data.attributes.url', '').replace(
        'https://storage.googleapis.com/artifacts-cms/',
        ''
      )
      const patternUrl = get(pattern, 'data.0.attributes.url', '').replace(
        'https://storage.googleapis.com/artifacts-cms/',
        ''
      )
      params.set('m', modelUrl)
      params.set('p', patternUrl)
      params.set('t', title)
      params.set('s', scale)
      params.set('h', rotateDeg)
      return `/scan/marker.html?${params.toString()}`
    }
  }
}
export const onClickHandle = async ({ groupId, optionId, extra }: any) => {
  try {
    const res = await updateStepper(groupId as string, optionId, extra)
    // if (res.status === 302) {
    // const { step } = await res.json()
    // setLocalResources(step)
    // const arScanner = window.open(`${arUrlAdapter(step.type, step.stepFlow[0].stepContent)}`, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no');
    // void popupBlockerChecker.check(arScanner);
    // const time = setTimeout(() => {
    //   arScanner?.close()
    //   updateStepper(groupId, optionId, { skipScene: true })
    // }, 60000);
    // (window as any).sendARMessage = ((groupId: string, optionId: string) => {
    //   return (success: boolean) => {
    //     if (success) {
    //       arScanner?.close()
    //       clearTimeout(time)
    //       updateStepper(groupId, optionId, { skipScene: true })
    //     }
    //   }
    // })(groupId as string, optionId);
    // }
    console.log(res)
    return res
  } catch (e) {
    console.log(e)
  }
}
const defaultIcon = '/icon/arrow-right.svg'
const StepperButton = ({ item, onClick, loading }: any) => {
  const { title, icon } = item
  const hasTitle = has(item, 'title') && item.title
  const showIcon = has(icon, 'data.attributes.url')
  const showDefaultIcon = !hasTitle && !showIcon
  return (
    <Button
      color={'primary'}
      theme={'light'}
      text={
        <>
          {showDefaultIcon && <Image src={defaultIcon} style={{ maxHeight: '100%' }} />}
          {showIcon && <Image src={get(icon, 'data.attributes.url')} style={{ maxHeight: '100%' }} />}
          {title}
        </>
      }
      loading={loading}
      onClick={() => {
        onClick()
      }}
    />
  )
}
const Options = ({
  stepController,
  extra = {},
  setExtra = () => {
    return ''
  },
  onClick = onClickHandle,
  correct = true,
}: any) => {
  const { id: groupId } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  console.log('loading',loading);
  return (
    <>
      {React.Children.toArray(
        stepController.map((option: any, index: number) => {
          return (
            <StepperButton
              key={`popup-option-${index}`}
              loading={loading}
              setLoading={setLoading}
              item={option}
              onClick={async () => {
                setLoading(true)
                if (correct) {
                  await onClick({ groupId, optionId: option.id, extra })
                  setError('')
                } else {
                  setError(i18n._('Wrong answer. Please try again.'))
                }
                setExtra('')
                // history.pushState(null, '', `?t=${Date.now()}`)
                setLoading(false)
              }}
            />
          )
        })
      )}
      <div>{error}</div>
      {loading && <Loader position={'right'} />}
    </>
  )
}

export default observer(Options)
